<template>
    <v-row>
        <v-col sm="12" cols="12" class="pt-0">
            <h2 class="mb-0 font_20">{{ $t('payment_options') }}</h2>
            <v-dialog v-model="dialogM2" max-width="480px">
            </v-dialog>
            <p class="mb-0">
                {{ $t('bu_location_setting_desc') }}
            </p>
        </v-col>
        <v-col sm="12" cols="12">
            <template>
                <v-simple-table class="attachment_table">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th>{{ $t('code') }}</th>
                            <th>{{ $t('name') }}</th>
                            <th>{{ $t('type') }}</th>
                            <th>{{ $t('use') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="pl in paymentOptions" v-bind:key="pl.id">
                            <td>{{ pl.code }}</td>
                            <td>{{ pl.name }}</td>
                            <td>{{ pl.type }}</td>
                            <td>
                                <v-switch
                                    color="primary"
                                    v-model="pl.isUsed"
                                    :label="pl.isUsed ? 'YES' : 'NO'"
                                />
                            </td>
                        </tr>
                        <LoadingMe
                            :isLoading="showLoading"
                            :fullPage="false"
                            :myLoading="true"/>
                        </tbody>
                    </template>
                </v-simple-table>
                
            </template>
            <div class="function_footer">
                <v-btn color="primary" class="float-right white--text text-capitalize"
                       @click="onSaveClose">
                    {{ $t('save') }}
                </v-btn>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import PaymentOptionModel from "@/scripts/model/pos/PaymentOption"
import kendo from "@progress/kendo-ui";
const paymentOptionHandler = require("@/scripts/paymentOptionHandler")
const $ = kendo.jQuery
// const accountHandler = require("@/scripts/accountHandler")
const paymentOptionModel = new PaymentOptionModel({})
const posHandler = require("@/scripts/posHandler")
const OPTION_TYPE = 'Customer'
export default {
    components: {
        LoadingMe: () => import(`@/components/Loading`)
    },
    data: () => ({
        dialogM2: false,
        isHideBank: true,
        showLoading: true,
        isLoaded: false,
        paymentOptions: [],
        mLocation: {},
        paymentOption: paymentOptionModel,
        schemaDefinition: {
            model: {id: "id"}
        },
        btnEnable: false,
        p: new PaymentOptionModel({})
    }),
    mounted: async function () {
        await this.loadPaymentOption()
    },
    created() {

    },
    methods: {
        async loadPaymentOption() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved')
                    this.paymentOptions = []
                    paymentOptionHandler.list('?optionType=' + OPTION_TYPE).then(res => {
                        this.showLoading = true
                        if (res.data.statusCode === 200) {
                            this.showLoading = false
                            let data = res.data.data
                            if(data.length > 0){
                                let self = this
                                $.each(data, function (i,v){
                                    self.paymentOptions.push({
                                        id: v.id,
                                        code: v.code,
                                        name: v.name,
                                        type: v.type,
                                        isUsed: false
                                    })
                                })
                                this.loadSPaymentOption()
                            }
                        }
                    }).catch()
                    {
                        this.showLoading = false
                    }
                }, 300)
            })
        },
        loadSPaymentOption() {
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve("resolved");
                    this.showLoading = true
                    posHandler.paymentOptionGet().then((res) => {
                        this.showLoading = false
                        const data = res.data.data
                        if (data.length > 0) {
                            this.p = data[0]
                            $.each(this.paymentOptions, function (i,v){
                                let d = data[0].paymentOption.filter((obj) => {return obj.id === v.id && obj.isUsed == true})
                                if(d.length > 0){
                                    v.isUsed = true
                                }
                            })
                        }
                    });
                }, 10)
            });
        },
        async onSaveClose() {
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve("resolved");
                    this.showLoading = true
                    this.p.paymentOption = this.paymentOptions
                    posHandler.paymentOptionPost(new PaymentOptionModel(this.p))
                        .then((response) => {
                            if (response.data.statusCode === 201) {
                                this.showLoading = false
                                this.$snotify.success('Success')
                            }
                        })
                        .catch((e) => {
                            this.showLoading = false
                            this.$snotify.error('Something went wrong')
                            this.errors.push(e);
                        });
                }, 10)
            });
        },
    }
};
</script>
<style scoped>

.acc_group header {
    height: 10px !important;
}

table.acc_group tr td {
    border-bottom: 1px solid #ccc;
    padding: 8px;
    vertical-align: middle;
}

table.acc_group tr td:first-child {
    font-weight: 700;
}

table.acc_group tr td:last-child {
    text-align: center;
}

.v-card__actions .v-btn.v-btn {
    padding: 0 16px;
}

.exchange-table.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-family: 'Krasar-Bold', serif;
    color: #000 !important;
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
    font-size: 15px !important;
}

.exchange-table.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > td {
    color: #000 !important;
    padding: 5px !important;
}

.exchange-table.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:last-child td {
    border-bottom: 1px solid #000 !important;
}

.function_footer {
    padding: 15px;
    display: inline-block;
}

@media (max-width: 576px) {

}
</style>